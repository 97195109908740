import { createGlobalStyle, css } from "styled-components"
import vars from "styles/variables"
import { optimaBase64Font } from "utils/fontUtil"

const styles = css`
  @font-face {
    font-family: "optima";
    src: url(${optimaBase64Font});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.15;
    font-size: 14px;
  }

  .lock-body {
    overflow: hidden;
    @media (min-width: ${vars.screen_md_min}) {
      padding-right: 17px; //size of the scrollbar in each browser

      header.is-fixed {
        padding-right: 17px;
      }
    }
  }

  .no-sroll {
    overflow: hidden;
  }

  main {
    flex-grow: 1;
  }

  /* If you set default link colour and hover, active, focus state then the button component
  will need to be updated also as the style changes will apply to the button */
  a {
    color: ${vars.black};
    text-decoration: none;

    &.primary-link {
      color: ${vars.primary};
      transition: ease-in-out 200ms;

      &:hover {
        color: ${vars.secondary};
      }

      &:active,
      &:focus {
        color: ${vars.primary};
      }
    }
  }

  .img-fluid {
    max-width: 100%;
  }

  h1,
  h2,
  h3 {
    font-family: "optima", sans-serif;
    font-weight: normal;
  }

  h1 {
    font-size: 2.57rem;
    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a,
  input,
  button,
  textarea {
    outline: none;
  }

  p {
    line-height: 1.5;
  }

  .slick-slide {
    div {
      outline: none;
    }
  }

  .slick-dots {
    li {
      &.slick-active {
        button:before {
          color: ${vars.lightBlue} !important;
        }
      }

      button:before {
        color: ${vars.white};
        text-shadow: 0 0 4px ${vars.grey_800};
        font-size: 12px;
        opacity: 1 !important;
      }
    }
  }

  .top-1rem {
    top: 1rem;
  }

  .top-1_5rem {
    top: 1.5rem;
  }

  .top-2rem {
    top: 2rem;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${styles}
`
